export const sectionsList = ["Portuguese",]
export const schoolsList = ["SJS-P","Library",]
export const statusList = [[1,"active"],[0, "suspend"],]
export const classesList = ["6º","7º","8º","9º","10","11º",]

export const readerType = 'none' // Switch type of reader to use, between values 'none, 'qrcode', 'barcode' or 'both'
export const showSpeechRecognition = false; // Switch show Speech Recognition on navbar
export const showMonitor = false; // Switch show Monitor on navbar
export const showNotifications = false; // Switch show Notifications on navbar

// used on activy office logs, for return associated, released and opened lockers as only one action
export const lockerLogsAssociateActions = [1001, 1003, 1005, 1007]
export const lockerLogsReleaseActions = [1002, 1004, 1006, 1008]
export const lockerLogsOpenActions = [0, 4, 5, 6, 7]
export const lockerLogsDashActions = [0, 4, 1007, 1008, 1009]
export const lockerLogsMobileActions = [6, 1005, 1006]
export const lockerLogsScreenActions = [5, 1003, 1004]
export const lockerLogsReaderActions = [7, 1001, 1002]

export const marketplaceCreateActions = [30, 40, 50, 60, 70, 80, 90, 100]
export const marketplaceEditActions = [31, 41, 51, 61, 71, 81, 91, 101]
export const marketplaceDeleteActions = [32, 42, 52, 62, 72, 82, 92, 102]

export const floorsList = ["a","b", "c"]
export const usernamesList = ["ROMEU","LOKK LAB 4",]
export const locationsList = ["OPEN","CITY CENTER",]
export const rolesList = [
  {
    value: "admin", 
    name: "Administrator",
    enabled: true,
  },
  {
    value: "security", 
    name: "Security",
    enabled: true,
  },
  {
    value: "store", 
    name: "Store",
    enabled: false,
  },
  {
    value: "lokk_importer", 
    name: "Importer Store",
    enabled: false,
  },
]

export const userRoles = [
  {
    name: 'Screen',
    role: 'ROLE_SCREEN'
  },
  {
    name: 'Dash',
    role: 'ROLE_DASH'
  },
  {
    name: 'Store',
    role: 'ROLE_STORE'
  },
  {
    name: 'SuperAdmin',
    role: 'ROLE_SUPER_ADMIN'
  }
]
export const userTypeList = [[1, "employee"],[2, "intern"],]
export const months = {
   1 : "Jan",
   2 : "Feb",
   3 : "Mar",
   4 : "Apr",
   5 : "May",
   6 : "Jun",
   7 : "Jul",
   8 : "Aug",
   9 : "Sep",
   10 : "Oct",
   11 : "Nov",
   12 : "Dec"
}
export const lockerSizeList = ['s', 'm', 'l']
export const orderStates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const orderListStatesFilter = [1, 2, 3, 4, 5, 6, 8, 10, 11, 12, 13, 14, 15]
export const assetStatusList = [1, 2, 3, 4, 5, 6]
export const assetTagStatusList = [0, 1]
export const assetLogsStatusList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

export const companiesList = [
  "ATM",
  "CUF Descobertas",
  "Estagiário",
  "Estagiario Provisório",
  "ITAU",
  "Prossegur",
  "Visitante",
  "Outros"
]

export const functionsList = [
  "Administrativos",
  "Administ. Delegado",
  "Auxiliares",
  "Dirigentes",
  "Enfermeiros",
  "Internos - Compl. FP",
  "Médicos",
  "Serviços gerais",
  "Téc. Diag. Terap.",
  "Téc. Sup. Saúde",
  "Téc. Superiores",
  "Outras"
]
export const servicesList = [
  "Administrativos",
  "Ambulatório",
  "Angiografia",
  "AP Adultos",
  "Bloco Operatório",
  "Bloco / Esterilização",
  "Consultas Externas",
  "Domus Care",
  "Enfermarias",
  "Esterilização",
  "Farmácia HCC",
  "Farmácia HCIS",
  "Gastro",
  "Gestão Hoteleira HCIS",
  "Hospital de Dia",
  "Internamento Piso 0",
  "Internamento Piso 1",
  "Internamento Piso 2",
  "Medicina Dentária",
  "Pediatria",
  "Recepção A",
  "Ser. Domiciliários",
  "Técnicos Imagiologia",
  "Técnicos MFR",
  "Técnicos - Otorrinolaringologia",
  "UCIP / Angiografia",
  "Vários",
  "Angiografia",
  "AP Adultos",
  "Bloco Operatório",
  "Bloco / Esterilização",
  "Consultas Externas",
  "Direcção de Enfermagem",
  "Enfermagem - Pool",
  "Enfermarias",
  "Enfermeiros instrumentistas",
  "Esterilização",
  "Gastro",
  "Gestão Risco / Controlo Infecção",
  "Hospital de Dia",
  "UCIP",
  "UCIP / Angiografia",
  "Otorrinolaringologia",
  "Vários",
  "Armazem HCIS",
  "Gestão de Fardamento CUF Sul",
  "Limpeza HCIS",
  "Logística Sul",
  "Manutenção",
  "Manutenção - ATM",
  "Maqueiro",
  "Secretariado Administração",
  "Cozinha",
  "Cafetaria Eric Kayser",
  "Restaurante Piso 2",
  "Anestesiologia",
  "Cardiologia",
  "Podologia",
  "Ser.Domicil.",
  "Técnicos",
  "Técnicos - Cardiologia",
  "Técnicos - Higiene Oral",
  "Técnicos - Imagiologia",
  "Técnicos - Imagiologia Pool",
  "Técnicos - Medic. Física e Reabilitação",
  "Técnicos - Neurofisiologia",
  "Técnicos - Nutrição",
  "Técnicos - Oftalmologia",
  "Técnicos - Otorrinolaringologia",
  "Técnicos - Patologia Clínica",
  "Farmácia HCIS",
  "Técnicos - Psicologia"
]
export const unitList = [
  "CUF Inf. Santo",
  "CUF Tejo",
  "CUF Almada",
  "CUF Cascais",
  "CUF Descobertas",
  "CUF Porto",
  "CUF Santarém",
  "CUF Sintra",
  "CUF Torres Vedras",
  "CUF Viseu",
  "Outra"
]
