import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import locations from "./locations/"
import users from "./users/"
import dashboard from "./dashboard/"
import activity from "./activity/"
import project from "./project/"
import admins from "./admins/"
import i18n from "./i18n/"
import quote from "./quote/"
import alerts from "./alerts/"
import expired_lockers from "./expired_lockers/"
import service_status from "./service_status/"
import orders from "./orders/"
import ordersLogs from "./orders_logs/"
import assets from "./assets/"
import asset_categories from "./asset_categories/"
import user_groups from "./user_groups/"
import schedules from "./schedules/"
import opening_schedule from "./opening_schedule/"
import cativation_price from "./cativation_price/"
import marketplace_settings from "./marketplace_settings/"
import iva_tax from "./iva_tax/"
import voucher from "./voucher/"
import price from "./price/"
import terms from "./terms/"
import faqs from "./faqs/"
import transactions from "./transactions/"
import settings from "./settings/"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  locations: locations,
  users: users,
  dashboard: dashboard,
  activity: activity,
  project: project,
  admins: admins,
  i18n: i18n,
  quote: quote,
  alerts: alerts,
  expired_lockers: expired_lockers,
  service_status: service_status,
  orders: orders,
  ordersLogs: ordersLogs,
  assets: assets,
  asset_categories: asset_categories,
  user_groups: user_groups,
  schedules: schedules,
  opening_schedule: opening_schedule,
  cativation_price: cativation_price,
  marketplace_settings: marketplace_settings,
  iva_tax: iva_tax,
  voucher: voucher,
  price: price,
  terms: terms,
  faqs: faqs,
  transactions: transactions,
  settings: settings,
})

export default rootReducer
