import React from 'react'
import Websocket from 'react-websocket'

class Socket extends React.Component {
  handleWsOpen = () => {
    this.refWebSocket.sendMessage("{\"token\":\"" + this.props.token + "\"}");
  }

  handleWsMessage = (evt) => {
    if (evt.startsWith('{')) {
      const parsedMsg = JSON.parse(evt);

      const { MsgType, Data } = parsedMsg;

      console.log('SocketMessage', parsedMsg)

      switch (MsgType) {
        case 'LockerUpdate':
          this.props.handleLockerUpdate && this.props.handleLockerUpdate(Data);
          break;
        case 'UpdateTransactions':
          this.props.handleUpdateTransactions && this.props.handleUpdateTransactions(Data);
          break;
        case 'RefreshUsers':
          this.props.handleRefreshUsers && this.props.handleRefreshUsers(Data);
          break;
        case 'UpdateCapacity':
          this.props.handleUpdateCapacity && this.props.handleUpdateCapacity(Data);
          break;
        default:
          console.log(`Message type: ${MsgType}`);
          break;
      }
    }
  };

  render() {
    return (
      <Websocket
        url={process.env.REACT_APP_API_WS_URL + '/ws/location-status?access_token=' + this.props.token}
        onOpen={this.handleWsOpen}
        onMessage={this.handleWsMessage}
        reconnect={true}
        debug={true}
        ref={Websocket => {
          this.refWebSocket = Websocket;
        }}
      />
    )
  }
}

export default Socket
