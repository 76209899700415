import React from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import ReactCountryFlag from "react-country-flag"
import { changeLanguage } from "../../../redux/actions/i18n"
import { connect } from "react-redux"
import { ReactComponent as FlagPortugal } from 'assets/img/icons/FlagPortugal.svg';
import { ReactComponent as FlagUK } from 'assets/img/icons/FlagUK.svg';

class Dropdowncontrolled extends React.Component {

  state = {
    dropdownOpen: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.language !== state.language
    ) {
      return {
        language: props.language
      }
    }
    return null
  }

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  componentDidMount() {
    this.props.i18n.changeLanguage(this.state.language)
  }

  handleChangeLanguage(lang) {
    this.props.i18n.changeLanguage(lang)
    this.props.dispatch(changeLanguage(lang))
  }

  render() {
    let selectedLanguage
    let countryCode
    if (this.state.language === 'gb') {
      selectedLanguage = 'English'
      countryCode = 'gb'
    } else if (this.state.language === 'pt') {
      selectedLanguage = 'Português'
      countryCode = 'pt'
    } else {
      selectedLanguage = 'English'
      countryCode = 'gb'
    }
    return (
      <div className="navbar-language" >
        <Dropdown
          className="dropdown-language nav-item"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggleDropdown}
          data-tour="language">
          <DropdownToggle
            tag="a"
            className="nav-link"
          >
            <ReactCountryFlag
              className="country-flag"
              countryCode={countryCode}
              svg
            />
            <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
              {selectedLanguage}
            </span>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => this.handleChangeLanguage('en')}>
              <ReactCountryFlag className="country-flag" countryCode="gb" svg />
              <span className="ml-1">English</span>
            </DropdownItem>
            <DropdownItem onClick={() => this.handleChangeLanguage('pt')}>
              <ReactCountryFlag className="country-flag" countryCode="pt" svg />
              <span className="ml-1">Portuguese</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    language: state.i18n.language,
  }
}

export default connect(mapStateToProps)(Dropdowncontrolled)
